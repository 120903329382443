// 招商会演示相关接口
import { instance_demo } from '@/utils/notokenHttp.js'

// 获取科目
export const getSubject = function (params) {
  return instance_demo({
    url: '/api/v1/subject_list2',
    method: 'get',
    params
  })
}

// 获取考点 根据科目id,地区id
export const getPoint = function (params) {
  return instance_demo({
    url: '/api/v1/check_point',
    method: 'get',
    params
  })
}

// 获取二级考点 根据一级考点id
export const getSubPoint = function (params) {
  return instance_demo({
    url: '/api/v1/check_point2',
    method: 'get',
    params
  })
}

// 获取考点训练/二级考点训练的题目列表。
export const getPaper = function (data) {
  return instance_demo({
    url: '/api/v1/weak_train_two',
    method: 'post',
    data
  })
}


