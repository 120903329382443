<template>
  <div class="info_wrap">
    <div class="title">
      <span class="back_btn"
            @click="goBack"><img src="@/assets/icons/Left.png"
             alt=""></span>
      做题详情
    </div>
    <div class="topic_main_wrap"
         ref="topicListRef">
      <Topic class="topic_item"
             v-for="question,index in paperInfo.questions"
             :key="question.question_id"
             @setAnwer="setAnwer"
             :question="question"
             v-show="topicIndex==index" />
    </div>
    <div class="btns">
      <button class="last"
              @click="lastTopic"
              v-show="topicIndex!=0">上一题</button>
      <button class="next"
              @click="nextTopic">{{paperInfo&&paperInfo.questions&&paperInfo.questions.length&&topicIndex==paperInfo.questions.length-1?'完成':'下一题'}}</button>
      <!--  -->
    </div>
    <div class="dia_mask"
         v-show="showExplain || showStatic"></div>
    <div class="dialog_wrap"
         v-show="showExplain">
      <div class="dia_title">答题说明</div>
      <div class="sub_list">
        <div class="sub_item">
          1.选择题请点击选项方框。
        </div>
        <div class="sub_item">
          2.多选题、填空题和解答题请在自备纸张上填写题号和相应答案，完成试卷后进入答题卡页面，拍摄答案照片（最多只能上传6张照片，每张照片不超过10M），上传后点击提交答案。
        </div>
        <div class="sub_item">
          3.点击提交答案可以提前交卷，时间到后将自动提交选择题答案。

        </div>

      </div>
      <div class="dia_btns">
        <button class="sub_btn1"
                @click="goBack">取消</button>
        <button class="sub_btn2"
                @click="getTopicList">开始训练</button>
      </div>
    </div>
    <div class="dialog_bottom"
         v-show="showStatic">
      <div class="dia_title">答题情况<span class="close_btn"
              @click="closeDialog">
          <img src="@/assets/icons/关闭.png"
               alt=""></span></div>
      <div class="sub_list">
        <div class="answer_pre_list">
          <!-- <button @click="previewFile"
                  class="uploadImg">
            <img src="@/assets/icons/Image.png" />上传图片</button>
          <div class="answer_item"></div>
          <div class="answer_item"></div>
          <div class="answer_item"></div>
          <div class="answer_item"></div> -->
          <div :class="getClass(item)"
               v-for="(item,index) in group_list"
               :key="index"
               class="answer_item">
            <span v-if="checkTopic.indexOf(item.topic) != -1">
              {{getAvlue(item,index+1)}}
            </span>
            <span v-else>
              <button @click="previewFile(item)"
                      class="uploadImg">
                <div v-if="!item.answerImg"><img src="@/assets/icons/Image.png" />上传图片</div>
                <div class="preImg "
                     v-else>
                  <!-- <el-image v-if="item.answerImg"
                            :src="item.answerImg"
                            :key="index"
                            alt=""> </el-image> -->
                  <img v-if="item.answerImg"
                       :src="item.answerImg"
                       :key="index"
                       alt="" />
                </div>
              </button>

            </span>
          </div>
        </div>

        <div class="preAnswer"></div>
      </div>
      <div class="dia_btns">
        <button class="sub_btn2"
                @click="goBack">提交答案</button>
      </div>

    </div>
  </div>
</template>


<script>
import { getPaper } from '@/api/demo.js'
import Topic from './topicItem.vue'
import SelectAnswer from './select.vue'
import Vue from 'vue'

export default {
  data () {
    return {
      topicList: [],
      showExplain: true,
      showStatic: false,
      // showExplain: false,
      // showStatic: true,
      topicIndex: 1,
      imgSrcList: [],
      paperInfo: {},
      flatList: {},
      checkTopic: [1, 2, 3, 4, 9],
      group_list: []
    }
  },
  mounted () {

  },
  components: { SelectAnswer, Topic },
  methods: {
    getAvlue (item, index) {
      if (item.topic == 1 || item.topic == 9) {
        if (item.answer.length) {
          return item.answer.join(',')
        } else {
          return '未作答'
        }

      } else if (item.topic == 2 || item.topic == 3 || item.topic == 4) {
        if (item.answer.length) {
          let arr = item.answer.slice().sort()
          return arr.join('')
        } else {
          return '未作答'
        }
      }
      return index
    },
    getClass (item) {
      var str = ''
      // 单选题 topic
      if ((item.topic == 1 || item.topic == 9)) {//&& this.images.length
        str = ''
      }

      // 纸上作答   当上传图片之后 高亮
      if (this.checkTopic.indexOf(item.topic) == -1) {//&& this.images.length
        str = ''
      }
      if (item.answer && item.answer.length) {
        str = ''
      }
      else str = "no_answer"
      return str
    },
    async getTopicList () {
      let data = { type: 6 }
      if (this.$route.query.type == 1) {
        data.check_point_id = this.$route.query.id
      }
      if (this.$route.query.type == 2) {
        data.check_point_2_id = this.$route.query.id
      }
      const res = await getPaper(data)
      // this.topicList = res.data.list.questions
      this.publicQuestionsFunc(res.data.list)
      this.topicIndex = 0
      this.showExplain = false
    },

    goBack () {
      // this.$router.go(-1)
      this.$router.replace({
        name: 'demonstrate',
        params: {
          subject_id: this.$route.query.subject_id
        }
      })
    },
    nextTopic () {
      if (this.topicIndex == this.paperInfo.questions.length - 1) {
        var list = {};
        this.group_list = [];
        Object.values(this.flatList).forEach(item => {
          list[item.answer_id] = item
        })

        Object.values(list).forEach(item => {
          if (Number(item.question_score)) {
            this.group_list.push(item)
          }
        })
        this.showStatic = true
        return
      }

      this.topicIndex += 1
      this.$refs.topicListRef.scrollTop = 0
    },
    lastTopic () {
      if (this.topicIndex == 0) return
      this.topicIndex -= 1
      this.$refs.topicListRef.scrollTop = 0
    },
    closeDialog () {
      this.showStatic = false
    },

    publicQuestionsFunc (data) {
      data.images = []
      data.questions = data.questions.map(item => {
        item.question_big_score = 0;
        item.topic += ''
        item.topic = Number(item.topic)
        if (item.question_children && item.question_children.length) {
          let score = 0;
          item.question_children = item.question_children.map(element => {
            score += Number(element.question_score);
            element.topic = Number(element.topic)
            element.answer = [];
            return element;
          });
          item.question_big_score = score;
        } else {
          item.answer = [];
        }
        return item;
      })
      this.paperInfo = data;
      this.setFlatAndTopicNumber(this.paperInfo);
    },
    setFlatAndTopicNumber (info) {
      // 扁平化 引用this的question 也就是说flatlist 变化 paperInfo.questions也要跟着变
      var list = this.flatQuestion(info.questions)
      var obj = {}
      var topicNumber = 0;
      list.forEach(item => {
        obj[item.question_id] = item
        if (Number(item.question_score)) {
          topicNumber += 1;
        }
      });
      this.flatList = obj
      // 获取共有多少题目
      this.topicNumber = topicNumber
    },
    flatQuestion (list) {
      var allList = [];
      list.forEach(element => {
        allList.push(element)
        if (element.question_children && element.question_children) {
          allList = allList.concat(...element.question_children);
        }
      });
      return allList;
    },
    setAnwer (id, answer) {
      var question = this.flatList[id]
      // 重复点击 取消选中
      if (question.answer.indexOf(answer) != -1) {
        question.answer.splice(question.answer.indexOf(answer), 1)
        return
      }
      question.topic = Number(question.topic)

      if (question.topic == 1 || question.topic == 9) {
        question.answer = [answer];
      } else if ([2, 3, 4].indexOf(question.topic) != -1) {
        //双选
        if (question.answer.length == 2 && question.topic == 4) {
          return
        }
        question.answer.push(answer)
      }
    },


    /**
        * 显示选择上传的图片略缩图
        * 当选择了图片文件时触发这个方法
        */
    async previewFile (item, limit = 10, fileType = "image/*", isActive) {

      // Js内置文件流
      const res = await Vue.prototype.$get_file({ limit, fileType })
      let fileData = []
      fileData.push(res);
      const reader = new FileReader();
      let blob = new Blob(fileData, { type: fileType });
      // 老版本的谷歌内核readAsDataURL函数需要的参数直接就是file对象
      reader.readAsDataURL(blob);
      reader.onload = () => {
        // 这里的reader.result就是图片的base64了，直接放到img标签的src中就可以展示了，转换的操作是异步的
        // this.imgSrcList.push(reader.result);
        this.$set(item, 'answerImg', reader.result)
        // item.answerImg = reader.result

      }
    }

  }
}
</script>

<style lang="scss" scoped>
.info_wrap {
  position: relative;
  button {
    outline: 0;
  }
  .back_btn {
    position: absolute;
    display: inline-block;
    left: 16px;
    width: 20px;
    height: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .title {
    position: relative;
    background: #2196f3;
    text-align: center;
    color: #fff;
    padding: 11px;
    line-height: 22px;
    font-size: 16px;
    // font-size: 24px;
  }
  .topic_main_wrap {
    height: calc(100vh - 126px);
    overflow: auto;
    // padding: 12px 0px 0;
  }
  .btns {
    position: fixed;
    bottom: 0;
    margin-bottom: 16px;
    padding: 0 16px;
    display: flex;
    width: calc(100% - 32px);
  }
  .last {
    font-size: 14px;
    width: 88px;
    font-weight: 400;
    color: #2196f3;
    line-height: 22px;
    background: #fff;
    padding: 11px 0;
    border-radius: 100px 100px 100px 100px;
    border: 1px solid #2196f3;
    margin-right: 12px;
  }
  .next {
    flex: 1;
    background: #2196f3;
    border-radius: 100px 100px 100px 100px;
    font-size: 14px;
    // padding: 11px 107px;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    padding: 12px 0;
    border: 0;
  }
  .dia_mask,
  .dialog_wrap {
    position: fixed;
    left: 0;
    right: 0;
  }
  .dia_mask {
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  .dialog_bottom {
    position: fixed;
    bottom: 0;
    // height: 254px;
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    padding: 24px 0;
    width: 100%;
    .dia_title {
      position: relative;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 24px;
      margin-bottom: 8px;
      .close_btn {
        position: absolute;
        top: 0px;
        right: 24px;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .uploadImg {
      width: 52px;
      height: 48px;
      box-sizing: border-box;
      padding: 5px 2px;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: #666666;
      line-height: 18px;
      border: 0;
      background: #f5f5f5;
      border-radius: 4px 4px 4px 4px;
      img {
        display: block;
        width: 20px;
        height: 20px;
        margin: 0 auto;
      }
    }
    .dia_btns {
      display: flex;
      padding: 0 24px;
    }
    .sub_btn2 {
      flex: 1;
      width: 126px;
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      border-radius: 100px;
      border: 0;
      background: #2196f3;
      color: #fff;
    }
    .answer_item {
      width: 52px;
      height: 48px;
      background: #e3f2fd;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-weight: 500;
      color: #2196f3;
      line-height: 48px;
      text-align: center;
      margin-right: calc((100% - 260px) * 0.25);
      &:last-of-type {
        margin-right: 0;
      }
    }
    .no_answer {
      font-size: 12px;
      font-weight: 500;
      color: #f2413a;
      line-height: 48px;
      background: rgba(242, 65, 58, 0.1);
      border-radius: 4px 4px 4px 4px;
    }
    .preImg {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .answer_pre_list {
      display: flex;
      padding: 45px 16px;
      // justify-content: space-between;
      align-content: flex-start;
    }
  }
  .dialog_wrap {
    top: 18%;
    // left: 50%;
    // margin-left: -156px;
    // width: 312px;
    width: calc(100% - 64px);
    // margin: 0 32px;
    max-width: 480px;
    margin: 0 auto;
    box-sizing: border-box;
    // height: 320px;
    padding: 24px 0;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
    border-radius: 12px 12px 12px 12px;
    .dia_title {
      position: relative;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 24px;
      margin-bottom: 8px;
    }
    .sub_list {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      padding: 8px 24px 24px;
    }
    .dia_btns {
      display: flex;
      padding: 0 24px;
      justify-content: space-between;
      .sub_btn1,
      .sub_btn2 {
        width: 126px;
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        border-radius: 100px;
        border: 0;
      }
      .sub_btn1 {
        color: #2196f3;
        background: #e3f2fd;
      }
      .sub_btn2 {
        background: #2196f3;
        color: #fff;
      }
    }
  }
}
.subject_list::-webkit-scrollbar {
  display: none !important;
  width: 0px;
  height: 0px;
}
</style>