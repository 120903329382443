import { render, staticRenderFns } from "./topicItem.vue?vue&type=template&id=1fd016ba&scoped=true&"
import script from "./topicItem.vue?vue&type=script&lang=js&"
export * from "./topicItem.vue?vue&type=script&lang=js&"
import style0 from "./topicItem.vue?vue&type=style&index=0&id=1fd016ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fd016ba",
  null
  
)

export default component.exports